// value是使用该规则的表单元素的值
// 如何反馈校验成功还是失败，返回true才是成功，其他情况失败，返回失败原因。
export const useFormValidate = () => {
  const checkUsername = (value) => {
    if (!value) return '请输入用户名'
    if (!/^[a-zA-Z0-9]{0,10}$/.test(value)) {
      return '请输入10位以内英文和数字'
    }
    return true
  }
  const checkChineseName = (value) => {
    if (!value) return true
    if (!/^[\u0391-\uFFE5]{0,10}$/.test(value)) {
      return '请输入10位以内中文'
    }
    return true
  }
  const checkEnglishName = (value) => {
    if (!/^[a-z]{0,10}$/i.test(value)) {
      return '请输入10位以内英文'
    }
    return true
  }
  const checkUserEmail = (value) => {
    if (!/^[a-zA-Z0-9@.]{0,30}$/.test(value)) {
      return '请输入正确的邮箱格式'
    }
    return true
  }
  const checkWechat = (value) => {
    if (!/^[a-zA-Z0-9_-]{0,20}$/.test(value)) {
      return '请输入20位以内英文、数字和_-'
    }
    return true
  }
  const checkPassword = (value) => {
    if (!/^[a-zA-Z0-9~!@#$%^&*()_+{}|:"<>? ]{0,20}$/.test(value)) {
      return '请输入20位以内英文、数字和英文特殊符号'
    }
    return true
  }
  const checkRegisterPassword = (value) => {
    if (!value) return '请输入密码'
    if (!/^[a-zA-Z0-9~!@#$%^&*()_+{}|:"<>? ]{0,20}$/.test(value)) {
      return '请输入20位以内英文、数字和英文特殊符号'
    }
    return true
  }
  const checkGoodsName = (value) => {
    if (!value) return '请输入商品名称'
    if (!/^[\u4e00-\u9fa5a-zA-Z]{1,10}$/.test(value)) {
      return '请输入10位以内中文、英文'
    }
    return true
  }
  const checkGoodsTitle = (value) => {
    if (!value) return '请输入商品标题'
    if (!/^[\u4e00-\u9fa5a-zA-Z]{0,10}$/.test(value)) {
      return '请输入10位以内中文、英文'
    }
    return true
  }
  const checkNotNull = (value) => {
    if (!value) {
      return '请输入内容'
    }
    return true
  }

  return {
    checkUsername,
    checkChineseName,
    checkEnglishName,
    checkUserEmail,
    checkWechat,
    checkPassword,
    checkRegisterPassword,
    checkGoodsName,
    checkGoodsTitle,
    checkNotNull
  }
}
